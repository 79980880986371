const paging = {
  data() {
    return {
      MoreConditions: "更多条件", //  输入框绑定动态class
      isHaseMore: false, //false == 更多条件  true ==收起条件
      queryCondditionDatas: [], //查询列表动态数据

      //      -------  表格  ----------
      pageStart: 1, //  页码
      pageCount: 0, //  总条数
      pageTotal: this.common.pageTotals, //  一页几条
      tableHeigth: "75vh", //  表格高度
      selectTableData: [], //  表格选中的数据
      myTableHeard: [], //  我的表头
      tableData: [], //  表格数据

      tabelHeadeTitle: [], //默认表头
      show_sortTableHeard: false, //显示表格设置弹窗
      originalTabelHeadeTitle: [], //自定义排序表头(恢复默认用)
      allCheck: false, //  全选
      sortName: "模板", //  排序名
      selectLength: 0, //  显示的长度
      totalArr: [], //  表格需要合计的字段
      className: "", //  导出需要
      //      -------  表格  ----------
      theadScreen: {},
      showTimers:true,
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    let pageType = this.$route.query.pageType || ""; //isTabs==tab切换的页面,不刷新页面
    let isreloads = this.$route.query.isreload; //关闭页面的标记,关闭tab,不刷新页面
    if (pageType == "isTabs" || isreloads == "isreload") {
      this.initTable();
    }
  },
  // 每次进页面都会触发的写这里
  activated() {
    let pageType = this.$route.query.pageType || ""; //isTabs==tab切换的页面,不刷新页面
    let isreloads = this.$route.query.isreload; //关闭页面的标记,关闭tab,不刷新页面
    if (pageType == "isTabs" || isreloads == "isreload") {
      return false;
    } else {
      // 初始化页面(手动刷新页面)
      this.initTable();
    }
  },
  methods: {
    async getData() {
      let conditionReqs = [];
      let F_config = this.$refs.queryForm.F_config;
      F_config.forEach(async (element) => {
        let {
          textBoxType,
          conditonKey,
          dropdownSubmitField,
          conditonVal,
          defaultSelectVal,
          timeType,
          timeChilds,
          startTime_a,
          endTime_a,
          dropdownShowField,
          dropdownApiNumber,
        } = element;
        switch (textBoxType) {
          case "input":
            conditionReqs.push({
              conditonKey,
              conditonVal,
            });
            break;
          case "input_more":
            conditionReqs.push({
              conditonKey,
              conditonVal: (conditonVal && conditonVal.split("/")) || [],
            });
            break;
          case "select":
            conditionReqs.push({
              conditonKey: conditonKey,
              // conditonKey: dropdownSubmitField || conditonKey,
              conditonVal,
            });
            break;
          case "select_object":
            conditionReqs.push({
              conditonKey: conditonKey,
              conditonVal,
            });
            break;
          case "datetime":
            let curs = timeChilds[timeType];
            curs &&
              curs.children.forEach((element, index) => {
                let endTime_agg = endTime_a ? `${endTime_a} 23:59:59` : "";
                let startTime_agg = startTime_a
                  ? `${startTime_a} 00:00:00`
                  : "";
                conditionReqs.push({
                  conditonKey: element.conditonKey,
                  conditonVal: index == 1 ? endTime_agg : startTime_agg,
                });
              });
            break;
          case "date":
            let curs2 = timeChilds[timeType];
            curs2 &&
              curs2.children.forEach((element, index) => {
                let endTime_agg = endTime_a ? `${endTime_a}` : "";
                let startTime_agg = startTime_a ? `${startTime_a}` : "";
                conditionReqs.push({
                  conditonKey: element.conditonKey,
                  conditonVal: index == 1 ? endTime_agg : startTime_agg,
                });
              });
            break;
          case "date_month":
            conditionReqs.push({
              conditonKey: conditonKey,
              conditonVal,
            });
            break;
          default:
            break;
        }
      });
      let params = {
        conditionReqs,
        pageStart: this.pageStart,
        pageTotal: this.pageTotal,
        number: this.reportFiledsNumber,
      };

      await this.$API.runPageReport(params).then((res) => {
        if (res.data.status === "success") {
          let datas = res.data.result.datas || {};
          this.className = datas.className || "";
          this.tableData = datas.data || [];
          this.pageCount = datas.pageCount || 0;
          this.$nextTick(() => {
            this.fetTableHeight();
          });
        } else {
          this.$message.error(`${res.data.message}---综合查询列表`);
        }
      });
    },
    async initTable() {
      this.tableData = [];
      this.queryCondditionDatas = [];
      this.theadScreen = {};

      //获取当前页面已设置表头
      await this.$API
        .getGroupFindFieldSort({
          groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        })
        .then((res) => {

          let arr = res.data.result || [];
          if (arr.length > 0) {
            if (arr[0].sortObject) {
              this.myTableHeard = JSON.parse(arr[0].sortObject);
              this.sortName = arr[0].sortName;
            }
          } else {
            this.myTableHeard = [];
          }
          let totalArr = [];
          //提取需要合计的字段
          for (var i = 0; i < this.myTableHeard.length; i++) {
            if (this.myTableHeard[i].isTotal) {
              totalArr.push(this.myTableHeard[i].field);
            }
          }
          this.totalArr = totalArr;
        });

      //表格 表头默认
      await this.$API
        .getReportFileds({
          number: this.reportFiledsNumber,
        })
        .then((res) => {

          let datas = res.data.result || [];
          let lists = [];
          datas.forEach((element) => {
            lists.push({
              name: element.fieldNameShow,
              field: element.fieldAlias,
              width: element.wide,
              isTotal: element.isSum,
              sort: element.sort,
              isShow: element.isShow == 0 ? false : true,
              isSort: true,
            });
          });
          this.originalTabelHeadeTitle = lists;
          if (!this.tabelHeadeTitle.length) {
            this.tabelHeadeTitle = this.originalTabelHeadeTitle;
          }
        });
      if (this.myTableHeard.length == 0) {
        this.myTableHeard = this.tabelHeadeTitle;
      }

      let arrsConddition = [];
      // 获取查询条件 list

      await this.$API
        .getReportConditions({
          number: this.reportFiledsNumber,
        })
        .then((res) => {

          let datas = res.data.result || [];
          if (datas && datas.length > 0) {
            datas.map((n) => (n.conditonVal = n.conditonVal || ""));
            let arrs = datas.filter((n) => {
              return n.textBoxType === "datetime" && n.isShow;
            });

            let timeobjs = {};
            arrs.forEach((item) => {
              let { sort, conditonKeyShow } = item;
              let name = conditonKeyShow.includes("起始")
                ? conditonKeyShow.split("起始").join("")
                : conditonKeyShow.split("结束").join("");
              if (!timeobjs[sort]) {
                timeobjs[sort] = {
                  sort,
                  name,
                  children: [],
                };
              }
              timeobjs[sort].children.push(item);
            });
            let gdates = [];
            let dates = Object.values(timeobjs);

            if (dates.length > 0) {

              let listA = dates.map((n, i) => {
                return { value: String(i), label: n.name };
              })
              let listB = dates.map((n, i) => {
                return { sort: n.sort, name: n.name, children: n.children };
              })

              let obj = {
                textBoxType: "datetime",
                startTime_a: this.showTimers? this.Tools.getFlexDate(this.common.timeNum) : '',
                endTime_a: this.showTimers? this.Tools.getCurrentDate() : '',
                timeType: "0",
                timeTypes: listA,
                timeChilds: listB,
                isShow: 1,
              }
              gdates.push(obj)

            }

            let arrs2 = datas.filter((n) => {
              return n.textBoxType === "date" && n.isShow;
            });

            let timeobjs2 = {};
            arrs2.forEach((item) => {
              let { sort, conditonKeyShow } = item;
              let name = conditonKeyShow.includes("起始")
                ? conditonKeyShow.split("起始").join("")
                : conditonKeyShow.split("结束").join("");
              if (!timeobjs2[sort]) {
                timeobjs2[sort] = {
                  sort,
                  name,
                  children: [],
                };
              }
              timeobjs2[sort].children.push(item);
            });

            let gdates2 = [];
            let dates2 = Object.values(timeobjs2);

            if (dates2.length) {
              gdates2 = [
                {
                  textBoxType: "date",
                  startTime_a: this.showTimers? this.Tools.getFlexDate(this.common.timeNum) : '',
                  endTime_a:  this.showTimers? this.Tools.getCurrentDate() : '',
                  timeType: "0",
                  timeTypes: dates2.map((n, i) => {
                    return { value: String(i), label: n.name };
                  }),
                  timeChilds: dates2.map((n, i) => {
                    return { sort: n.sort, name: n.name, children: n.children };
                  }),
                  isShow: 1,
                },
              ];
            }
            arrsConddition = [
              ...gdates,
              ...gdates2,
              ...datas.filter((n) => {
                return n.textBoxType !== "datetime" && n.textBoxType !== "date";
              }),
            ];


          }
        })
        .catch((error) => { });
      // 获取 每个查询条件 的下拉数据

      const promisemap = arrsConddition.map(async (element, index) => {
        let {
          textBoxType,
          dropdownShowField,
          dropdownApiNumber,
          defaultSelectVal,
        } = element;
        if (textBoxType == "select") {
          let selectOptions = [];
          if (defaultSelectVal) {
            await this.$API
              .runPageReport({
                conditionReqs: [
                  {
                    conditonKey: dropdownShowField,
                    conditonVal: "",
                  },
                ],
                number: dropdownApiNumber,
                pageStart: 1,
                pageTotal: 20,
              })
              .then((res) => {
                let datas = res.data.result.datas.data || [];
                selectOptions = datas;
              })
              .catch((error) => {
                selectOptions = [];
              });
            element.conditonVal = defaultSelectVal || "";
          }
          element.selectOptions = selectOptions;
        }
      });
      await Promise.all(promisemap);
      this.queryCondditionDatas = arrsConddition;
      // this.fetTableHeight();
      // setTimeout(() => {
      //   this.queryBtn_ok();
      // }, 0);
    },
    // 点击导出 (页面标识,表头,列表标识,数据源,表名)
    exportBtn() {
      this.$COMAPI.exportBtn({
        pageGroupName: this.pageGroupName, //页面标识
        myTableHeard: this.myTableHeard, //表头数组
        className: this.className, //列表标识
        tableData: this.tableData, //数据源
        sheetName: "表名(暂无效)", //表名
      });
    },
    // 点击选中当前行
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    clerBtns() {
      //  重置按钮
      this.initTable();
    },
    queryBtn_ok() {
      //  查询按钮
      this.pageStart = 1;
      this.getData();
    },
    moreParam() {
      this.isHaseMore = !this.isHaseMore;
      if (this.isHaseMore) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }
      this.$nextTick(() => {
        this.fetTableHeight();
      });
    },

    handleSizeChange(val) {
      //  分页
      this.pageTotal = val;
      this.getData();
    },
    handleCurrentChange(val) {
      //  分页
      this.pageStart = val;
      this.getData();
    },

    handleSelectionChange(e) {
      // 表格选择事件
      this.selectTableData = e;
    },
    // 表格合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property === that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return this.Tools.mathToFixed(
                    this.Tools.add(prev, curr),
                    2
                  );
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },
    /***
     * ==============设置表格动态高度==============
     */
    fetTableHeight() {
      // 重置table高度
      this.tabelHeigth = 0;
      // 设置table高度
      let windonHeight =
        document.body.clientHeight || document.documentElement.clientHeight; //页面高度
      let queryHeigth = 40 + 10; //10==margin-top: 10  查询框高度
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表高度
      if (this.isHaseMore) {
        queryHeigth = this.$refs.queryForm.$refs.queryForm.offsetHeight + 10; //查询框
      }
      let pagsHeigth = 40 + 20; //分页组件高度 20是上下margin
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60; //60是顶部导航 10是
      let tabHeight = Math.floor(windonHeight - otherHeight) || ""; //

      this.$nextTick(() => {
        this.tableHeigth = tabHeight;
        this.$refs.mytable.doLayout();
      });
    },

    /****
     *  ===================下面页面公共方法 start ===================
     *
     */

    //顶部跟多按钮的操作
    topCommandBtns(a) {
      if (a === "bgsz") {
        // 设置模板按钮
        // this.myModel();
        this.Tools.myModel(this);
      }
      if (a === "dc") {
        // 导出
        this.exportBtn();
      }
      if (a === "dtdc") {
        // 动态列表的导出
        this.isShowDialog_dc = true;
      }
      if (a === "printBtns") {
        this.printEvent();
      }
    },

    bgszFn() {
      this.Tools.myModel(this);
    },

    // ===================排序表头彈窗的组件方法 start ===================
    //   取消按钮(表格设置)
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮(表格设置)
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let param = {
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
      };
      this.$API.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        //获取默认表头
        this.Tools.getGroupFindFieldSort(this, this.pageGroupName);
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项(表格设置)
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选(表格设置)
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗(表格设置)
    closeDias(e) {
      this.show_sortTableHeard = e;
    },

    /****
     *  ===================排序表头彈窗的组件方法 end ===================
     *
     */
  },
};

export default paging;
